<template>
  <div class="position-relative">

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :src="avatar"
          :text="avatarText(userData.name)"
          variant="light-danger"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="handleImageUpload(userData.id)"
        >
          <input
            ref="refInputAvatar"
            type="file"
            class="d-none"
            @change="uploadImage"
            accept="image/png, image/jpeg"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>

        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="handleImageRemove(userData.id)"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->

      </b-row>
    </b-form>

    <b-overlay
    :show="loader"
    no-wrap
    spinner-variant="primary"
    />

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
  BOverlay
} from 'bootstrap-vue'
//import { avatarText } from '@core/utils/filter'
//import { ref } from '@vue/composition-api'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import User from '../../Helpers/User'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BOverlay,
    ToastificationContent
  },

  data(){
      return {   
          userid:null,
          loader:false
      }

  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods:{
      handleImageUpload(userid){
          this.$refs.refInputAvatar.click()
          this.userid = userid
      },
      uploadImage(){

        this.loader = true
        var file = this.$refs.refInputAvatar.files[0]
        
        let formData = new FormData();
        formData.append('file', file);
        formData.append('userid', this.userid);
        
        axios.post(process.env.VUE_APP_API+'/upload-user-avatar' , formData,{
                                                                    headers: {
                                                                         'Content-Type': 'multipart/form-data'
                                                                    }
                })
        .then(res=>{
            if(res.data.success){
                localStorage.setItem('useravatar',res.data.avatar)
                //store.commit('myStore/USER_AVATAR', res.data.avatar)
                this.$emit('refresh')
                this.loader=false
            }
            else{
              this.loader = false
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Failed',
                  icon: 'AlertOctagonIcon',
                  text: 'Avatar upload failed',
                  variant:'danger',
                  },     
              },
              {
                  position:'top-right'
              })
            }
        })
        .catch((error) => {
          this.loader = false
          console.log(error);
          });
      },
      handleImageRemove(userid){
        this.loader = true
        axios({
            method: 'post',
            url: process.env.VUE_APP_API+'/remove-user-avatar',
            data:{
              'userid':userid
            }       
            }).
            then(res=>{
              if(res.data.success){

                //store.commit('myStore/USER_AVATAR', res.data.avatar)
                localStorage.removeItem('useravatar')
                this.$emit('refresh')
                this.loader = false
              }
              else{
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: 'Can not remove avatar',
                    variant:'danger',
                    },     
                },
                {
                    position:'top-right'
                })
              }
            })
            .catch((error) => {
              this.loader = false
              console.log(error);
              });
      },
      avatarText(username){
        if (!username) return ''
        return username.charAt(0).toUpperCase()
      },

  },
  computed:{
    avatar(){
      if(store.state.myStore.avatar){
        return process.env.VUE_APP_USER_AVATAR+`/${store.state.myStore.avatar}`
      }
    },
    avatar_url(){
      if(User.avatar()){
        return process.env.VUE_APP_USER_AVATAR+`/${User.avatar()}`
      }
      
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
